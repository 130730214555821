import dotenv from 'dotenv'
import axios from "axios";
import authHeader from '../Authentification/authHeader';


dotenv.config()
const { REACT_APP_API_URL } = process.env;

//GET URL BACKEND 
const url = REACT_APP_API_URL
const user = JSON.parse(localStorage.getItem('user'));
const role=user?.result?.role_code
const user_code =user?.result?.code

/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getUsersRapport( today ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportJournalier/users/"+today+"/"+datefin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * UPDATE RAPPORT DATA
 * @param {*} data 
 * @returns 
 */
export async function updateRapport( data ) {
    try {
        const response = await axios.patch(url + "rapports/updateRapports",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET RAPPORT MERCHANDISEUR
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
export async function getRapportMerchandiseur( datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportMerchandiseur/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportGestionDashboard( datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportGestionDashboard/"+datedebut+"/"+datefin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getRapports() {
    try {
        const response = await axios.get(url + "rapports/allRapports", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportJournalier_1( today ) {
    try {
        const response = await axios.get(url + "rapports/rapportJournalier_1/"+today+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function rapportDN( today ) {
    try {
        const response = await axios.get(url + "rapports/rapport_DN/"+today, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function rapportSuiviRelevePrix( datedebut, datefin ) {
    try {
        const response = await axios.get(url + "rapports/suiviRelevePrix/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET 3 LAST RELEVE PRIX
 * @returns list
 */
 export async function getLastRelve(  ) {
    try {
        const response = await axios.get(url + "relevePrix/lastReleve", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET 3 LAST RELEVE PRIX
 * @returns list
 */
 export async function getLastRuptures(  ) {
    try {
        const response = await axios.get(url + "relevePrix/lastRuptures", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET 3 LAST PART LINEAIRE
 * @returns list
 */
 export async function getLastPL(  ) {
    try {
        const response = await axios.get(url + "relevePrix/lastPL", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function PostrapportSuiviRelevePrix( datedebut, datefin,pdv_code, data ) {
    try {
        const response = await axios.post(url + "rapports/suiviRelevePrix/"+datedebut+"/"+datefin+"/"+pdv_code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function rapportSuiviRuptures( datedebut, datefin ) {
    try {
        const response = await axios.get(url + "rapports/suiviRupture/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function rapportRupturetMerch( datedebut, datefin,codemerch ) {
    try {
        const response = await axios.get(url + "rapports/rapportMerch/"+datedebut+"/"+datefin+"/"+codemerch, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
  
    }
}
/**
 * get rapport sales issues 
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
export async function rapportSalesIssues( datedebut, datefin ) {
    try {
        const response = await axios.get(url + "rapports/StockIssuesReport/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function rapportSuiviPL( datedebut, datefin ) {
    try {
        const response = await axios.get(url + "rapports/suiviPL/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getrapportSuiviRuptures( datedebut, datefin , pdv_code ) {
    try {
        const response = await axios.get(url + "rapports/suiviRuptures/"+datedebut+"/"+datefin+"/"+pdv_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
} 
export async function PostrapportSuiviPL( datedebut, datefin,pdv_code, data ) {
    try {
        const response = await axios.post(url + "rapports/suiviPL/"+datedebut+"/"+datefin+"/"+pdv_code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function rapportDNSearch( today ,user_code ) {
    try {
        const response = await axios.get(url + "rapports/rapport_DN_search/"+today+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getDetailsRapportByCode(code, today ) {
    try {
        const response = await axios.get(url + "rapports/rapportJournalier/by/"+code+"/"+today, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET HISTORIQUE RUPTURE
 * @param {*} pdv_code 
 * @param {*} produit_code 
 * @returns 
 */
export async function getHistoriqueRupture(pdv_code, produit_code ) {
    try {
        const response = await axios.get(url + "rapports/historique/"+pdv_code+"/"+produit_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET HISTORIQUE RUPUTRE 2
 * @param {*} pdv_code 
 * @param {*} produit_code 
 * @returns 
 */
export async function getHistoriqueRupture2(pdv_code, produit_code ) {
    try {
        const response = await axios.get(url + "rapports/historique_rupture2/"+pdv_code+"/"+produit_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getDetailsMagasins(code, today ,pdv_code) {
    try {
        const response = await axios.get(url + "rapports/rapportJournalier/details/"+code+"/"+today+"/"+pdv_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET PLANNING MAGASIN
 * @param {*} categoriepdv_code 
 * @param {*} pdv_code 
 * @returns 
 */
export async function getPlanningMagasinbyPDV(categoriepdv_code ,pdv_code) {
    try {
        const response = await axios.get(url + "magasins/planning/"+categoriepdv_code+"/"+pdv_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function nbrContactParheure(code, today ,pdv_code) {
    try {
        const response = await axios.get(url + "rapports/rapportJournalier/nbHeure/"+code+"/"+today+"/"+pdv_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET ASSORTIMENT PL / RUPTURE / Promotions
 * @param {*} code 
 * @param {*} today 
 * @param {*} pdv_code 
 * @returns 
 */
export async function getAssortimentRapportJour(categorie_code, enseigne_code ,today) {
    try {
        const response = await axios.get(url + "rapports/rapportJournalier/sumAss/"+categorie_code+"/"+enseigne_code+"/"+today, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRupturesProduits(code, today ,pdv_code) {
    try {
        const response = await axios.get(url + "rapports/rapportJournalier/ruptures/"+code+"/"+today+"/"+pdv_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportRupture1(code, datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapport1/"+code+"/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET RAPPORT RUPTURE (POST)
 * @param {*} datedebut 
 * @param {*} datefin 
 * @param {*} data 
 * @returns 
 */
 export async function getRapportRupture1Post(datedebut ,datefin ,data) {
    try {
        const response = await axios.post(url + "rapports/rapport1/"+datedebut+"/"+datefin,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportRupture2( datedebut ,datefin , user_code ) {
    try {
        const response = await axios.get(url + "rapports/rapport2/"+datedebut+"/"+datefin+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * GET RAPPORT KPI
 * @param {*} datedebut 
 * @param {*} datefin 
 * @returns 
 */
export async function getRapportSalesKPI( datedebut ,datefin  ) {
    try {
        const response = await axios.get(url + "rapports/kpisales/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * POST METHODE
 * @param {*} datedebut 
 * @param {*} datefin 
 * @param {*} user_code 
 * @returns 
 */
export async function getRapportRupture2Post( datedebut ,datefin ,data ) {
    try {
        const response = await axios.post(url + "rapports/rapport2/"+datedebut+"/"+datefin, data,{ headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportVisiteMagasin(datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportvisite/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportSuiviPointage(datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportPointage/liste/"+datedebut+"/"+datefin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportSuiviPointageImage(date ,pdv_code, user_code,visiteCode ) {
    try {
        const response = await axios.get(url + "rapports/rapportPointage/images/"+date+"/"+pdv_code+"/"+user_code+"/"+visiteCode, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * Get Rapport Suivi Pointage Pause Debut 
 * @param {*} date 
 * @param {*} user_code 
 * @returns 
 */
export async function getRapportSuiviPointagePauseDebImage(date, user_code ) {
    try {
        const response = await axios.get(url + "rapports/rapportPointage/pointagedebut/"+date+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * Get Rapport Suivi Pointage Pause Fin 
 * @param {*} date 
 * @param {*} user_code 
 * @returns 
 */
 export async function getRapportSuiviPointagePauseFinImage(date, user_code ) {
    try {
        const response = await axios.get(url + "rapports/rapportPointage/pointagefin/"+date+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getRapportSuiviPointageImageSortie(date ,pdv_code, user_code, visiteCode) {
    try {
        const response = await axios.get(url + "rapports/rapportPointage/images_sortie/"+date+"/"+pdv_code+"/"+user_code+"/"+visiteCode, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 *
 * @param {*} code 
 * @returns 
 */
 export async function getRapportSuiviPointageLocalisation(date ,pdv_code, user_code ) {
    try {
        const response = await axios.get(url + "rapports/rapportPointage/localisation/"+date+"/"+pdv_code+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/*********** ----------------------- POINTAGE --------------------------------- */

/**
 * get Liste ParmPointage
 * @returns 
 */
 export async function getListeParmPointage( ) {
    try {
        const response = await axios.get(url + "rapports/parametere_pointage/", { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getParamPointageByCode(code ) {
    try {
        const response = await axios.get(url + "rapports/parametere_pointage/"+code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * ADD Parameteres Pointages
 * @param {*} data 
 * @returns 
 */
export async function addParmPointages(data ) {
    try {
        const response = await axios.post(url + "rapports/parametere_pointage/",data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * update Parm Pointages
 * @param {*} code 
 * @param {*} data 
 * @returns 
 */
export async function updateParmPointages(code, data ) {
    try {
        const response = await axios.patch(url + "rapports/parametere_pointage/"+code,data, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * DELETE PARAM POINTAGE
 * @param {*} id 
 * @returns 
 */
export async function deleteParmPointages(id ) {
    try {
        const response = await axios.delete(url + "rapports/parametere_pointage/"+id, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/************************ RAPPORT GESTION DE STOKC ------------------------------ */
/**
 * 
 * @returns 
 */
  export async function getRapportGestionStock(datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportGestionStock/"+datedebut+"/"+datefin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

export async function getRapportContact(datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportContacts/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

export async function detailsRapportContact(datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/detailsrapportContact/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getRapportVente(datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportVentes/"+datedebut+"/"+datefin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getRapportVenteContact(datedebut ,datefin,user_code ) {
   
    try {
        const response = await axios.get(url + "rapports/contactUser/"+datedebut+"/"+datefin+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
export async function getTop5(datedebut ,datefin ) {
    try {
        const response = await axios.get(url + "rapports/rapportVentes/top/"+datedebut+"/"+datefin+"/"+role+"/"+user_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getGraphPl( datedebut,datefin , enseigne_code ) {
    try {
        const response = await axios.get(url + "rapports/rapportGraphPL/"+datedebut+"/"+datefin+"/"+enseigne_code, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}

/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getGraphPl1( datedebut,datefin  ) {
    try {
        const response = await axios.get(url + "rapports/rapportGraphPL1/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}
/**
 * 
 * @param {*} code 
 * @returns 
 */
 export async function getPL( datedebut,datefin  ) {
    try {
        const response = await axios.get(url + "rapports/rapportPL/"+datedebut+"/"+datefin, { headers: authHeader() }, {
            IsEssential: true
        });
        return response;
    } catch (error) {
        if (error.response === undefined) {
            return undefined
        }
        else  return error?.response?.status 
    }
}